<template>
    <div class="stats-grid-col">
        <div class="stats-grid-col-label">{{ label }}</div>
        <div class="stats-grid-col-data">{{ data }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        data: {
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
    .stats-grid-col {
        padding: .5rem;
    }
    .stats-grid-col-label {
        font-size: .7rem;
    }
</style>
