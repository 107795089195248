import { EventEmitter } from './event-emitter';

export class AudioRecorder extends EventEmitter {

    mediaRecorder = null;
    countSeconds = 0;
    countPlays = 0;
    timestamp = null;

    constructor() {
        super();
    }

    get mediaRecorderIsReady() {
        return this.mediaRecorder;
    }

    async createMediaRecorder() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: false,
                    noiseSuppression: false,
                    autoGainControl:  false,
                },
            });
            this.mediaRecorder = new MediaRecorder(stream, {
                // audioBitsPerSecond: 128000,
            });
            this.mediaRecorder.addEventListener('dataavailable', this.onDataavailable.bind(this));
            // this.mediaRecorder.addEventListener('start', this.onStart.bind(this));
            // this.mediaRecorder.addEventListener('stop', this.onStop.bind(this));
        }
    }

    async start() {
        if(!this.mediaRecorderIsReady) {
            await this.createMediaRecorder();
        }
        if(this.mediaRecorder.state !== 'recording') {
            this.mediaRecorder.start();
            this.onStart();
        }
    }

    stop() {
        if(this.mediaRecorderIsReady) {
            if(this.mediaRecorder.state === 'recording') {
                this.mediaRecorder.stop();
                this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
                this.mediaRecorder = null;
                this.onStop();
            }
        }
    }

    async toggle() {
        if(this.isRecording) {
            this.stop();
        } else {
            await this.start();
        }
    }

    get isRecording() {
        return this.mediaRecorder && this.mediaRecorder.state === 'recording';
    }

    onDataavailable(event) {
        this.dispatchCustomEvent('dataavailable', event.data);
    }

    onStart(event) {
        this.countPlays += 1;
        this.timestamp = new Date();
        this.dispatchCustomEvent('start', event);
    }

    onStop(event) {
        this.setCountSeconds();
        this.dispatchCustomEvent('stop', event);
    }

    setCountSeconds() {
        if(this.timestamp) {
            this.countSeconds += (new Date().getTime() - this.timestamp.getTime()) / 1000;
        }
    }

}
