export class EventEmitter {

    constructor() {
        this.eventEmitter = document.createDocumentFragment();
    }

    addEventListener(type, listener, options) {
        this.eventEmitter.addEventListener(type, listener, options);
    }

    removeEventListener(type, listener, options) {
        this.eventEmitter.removeEventListener(type, listener, options);
    }

    dispatchCustomEvent(type, detail) {
        this.eventEmitter.dispatchEvent(new CustomEvent(type, {
            detail,
        }));
    }

    dispatchEvent(event) {
        this.eventEmitter.dispatchEvent(event);
    }

}
