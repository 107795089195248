export const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // setTimeout here is needed for Firefox
    setTimeout(function () {
        window.URL.revokeObjectURL(url);
    }, 100);
};
