import Vue from 'vue';
import Vuex from 'vuex';
import { downloadBlob } from './functions/download-blob';
import { AudioPlayer } from './classes/audio-player';
import { AudioRecorder } from './classes/audio-recorder';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        audioPlayer: null,
        audioRecorder: null,
        tracks: [],
        nextTrackNumber: 1,
        config: {
            elem: 'audio-recorder-component',
            src: null,
            audioRecorderEnabled: true,
            playbackRateEnabled: true,
            loopEnabled: true,
            showStatistics: true,
            mode: 'page', // page, absolute
            absoluteModeStyles: {
                width: '720px',
                top: '0px',
                left: '0px',
                bottom: 'auto',
                right: 'auto',
            },
            audioTrackRowAsideWidth: '240px',
            waveSurferRegionsPluginEnabled: true,
            waveSurferCursorPluginEnabled: true,
            encodeStatisticsFile: false,
            uploadAudioPlayerSrcEnabled: false,
        },
    },
    getters: {
        statistics: state => {
            const roundSeconds = s => Math.round(s * 100) / 100;
            return {
                player: {
                    countPlays: state.audioPlayer.countPlays,
                    countSeconds: roundSeconds(state.audioPlayer.countSeconds),
                },
                recorder: {
                    countPlays: state.audioRecorder.countPlays,
                    countSeconds: roundSeconds(state.audioRecorder.countSeconds),
                },
                tracks: state.tracks.map(track => {
                    return {
                        name: track.name,
                        countPlays: track.countPlays,
                        countSeconds: roundSeconds(track.countSeconds),
                    };
                }),
            };
        },
    },
    mutations: {
        setAudioPlayer(state, audioPlayer) {
            state.audioPlayer = audioPlayer;
        },
        setAudioRecorder(state, audioRecorder) {
            state.audioRecorder = audioRecorder;
        },
        addTrack(state, track) {
            state.tracks.push(track);
            state.nextTrackNumber += 1;
        },
        clearTracks(state) {
            state.tracks = [];
            state.nextTrackNumber = 1;
        },
        removeTrack(state, track) {
            for (let i = state.tracks.length - 1; i >= 0; --i) {
                if (state.tracks[i].id === track.id) {
                    state.tracks.splice(i, 1);
                    window.URL.revokeObjectURL(track.url);
                    break;
                }
            }
        },
        setConfig(state, config) {
            Object.assign(state.config, config);
        },
    },
    actions: {
        stopAllTracks({ state }) {
            state.tracks.forEach(track => {
                track.stop();
            });
        },
        downloadStatistics({ state, getters }) {
            let str = JSON.stringify(getters.statistics);
            if(state.config.encodeStatisticsFile) {
                str = btoa(str);
            }
            const blob = new Blob([str], {
                type: 'text/plain',
            });
            downloadBlob(blob, 'statistics.txt');
        },
        resetConfig({ commit }, config) {
            commit('setAudioPlayer', new AudioPlayer());
            commit('setAudioRecorder', new AudioRecorder());
            commit('clearTracks');
            commit('setConfig', config);
        },
    },
});
