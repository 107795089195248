import { EventEmitter } from './event-emitter';

export class AudioPlayer extends EventEmitter {

    audio = null;
    _duration = 0;
    _startTime = 0;
    _endTime = 0;
    loop = false;
    countSeconds = 0;
    countPlays = 0;
    timestamp = null;

    constructor(url) {
        super();
        this.audio = new Audio(url);
        this.audio.addEventListener('loadedmetadata', this.onLoadedmetadata.bind(this));
        this.audio.addEventListener('timeupdate', this.onTimeupdate.bind(this));
        this.audio.addEventListener('playing', this.onPlaying.bind(this));
        this.audio.addEventListener('ended', this.onEnded.bind(this));
        this.audio.addEventListener('pause', this.onPause.bind(this));
    }

    get src() {
        return this.audio.src;
    }

    set src(src) {
        this.audio.src = src;
    }

    async play(startAt) {
        try {
            if(typeof startAt === 'number') {
                this.currentTime = startAt;
            }
            if(this.currentTime < this.startTime) {
                this.currentTime = this.startTime;
            }
            if(this.currentTime < this.endTime) {
                await this.audio.play();
            }
        } catch(e) {
            console.error(e);
        }
    }

    async togglePlay() {
        if(this.audio.paused) {
            await this.play();
        } else {
            this.pause();
        }
    }

    toggleMute() {
        this.audio.muted = !this.audio.muted;
    }

    pause() {
        this.audio.pause();
    }

    stop() {
        this.audio.pause();
        this.currentTime = this.startTime;
    }

    stopOrReset() {
        if(this.currentTime === this.startTime) {
            this.reset();
        } else {
            this.stop();
        }
    }

    reset() {
        this.audio.pause();
        this.startTime = 0;
        this.currentTime = 0;
        this.endTime = this.duration;
    }

    rewind() {
        if(this.timestamp) {
            this.setCountSeconds();
            this.timestamp = new Date();
        }
        this.currentTime = this.startTime;
    }

    toggleLoop() {
        this.loop = !this.loop;
    }

    get isPlaying() {
        return !!(this.audio.currentTime > 0 && !this.audio.paused && !this.audio.ended && this.audio.readyState > 2);
    }

    get isMuted() {
        return !!this.audio.muted;
    }

    set volume(volume) {
        this.audio.volume = volume;
    }

    get volume() {
        return this.audio.volume;
    }

    set playbackRate(playbackRate) {
        this.audio.playbackRate = playbackRate;
    }

    get playbackRate() {
        return this.audio.playbackRate;
    }

    set currentTime(currentTime) {
        this.audio.currentTime = currentTime;
    }

    get currentTime() {
        return this.audio.currentTime;
    }

    get duration() {
        return this._duration;
    }

    set duration(duration) {
        this._duration = Math.ceil(duration * 100) / 100;
    }

    get startTime() {
        return this._startTime;
    }

    set startTime(startTime) {
        this._startTime = Math.ceil(startTime * 100) / 100;
    }

    get endTime() {
        return this._endTime;
    }

    set endTime(endTime) {
        this._endTime = Math.ceil(endTime * 100) / 100;
    }

    onLoadedmetadata(event) {
        this.duration = this.audio.duration;
        this.endTime = this.audio.duration;
        this.dispatchCustomEvent('loadedmetadata', event);
    }

    onTimeupdate(event) {
        this.dispatchCustomEvent('timeupdate', event);
        if(this.currentTime > this.endTime) {
            if(this.loop) {
                this.dispatchCustomEvent('loopend');
                this.rewind();
            } else {
                this.pause();
            }
        }
    }

    onPlaying(event) {
        this.countPlays += 1;
        this.timestamp = new Date();
        this.dispatchCustomEvent('playing', event);
    }

    onEnded(event) {
        this.setCountSeconds();
        this.timestamp = null;
        this.dispatchCustomEvent('ended', event);

        if (this.loop) {
            this.dispatchCustomEvent('loopend');
        }
    }

    onPause(event) {
        this.setCountSeconds();
        this.timestamp = null;
        this.dispatchCustomEvent('pause', event);
    }

    setCountSeconds() {
        if(this.timestamp) {
            this.countSeconds += (new Date().getTime() - this.timestamp.getTime()) / 1000;
        }
    }

}
