<template>
    <div class="control-group">
        <div v-if="label" class="control-group-label" @click="labelClicked">{{ label }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['label'],
    methods: {
        labelClicked() {
            this.$emit('labelClicked');
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/import";

    .control-group {
        margin-bottom: .5rem;
    }

    .control-group-label {
        font-size: .7rem;
    }
</style>
