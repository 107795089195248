<template>
    <div class="audio-recorder">
        <track-component v-for="track in tracks" :key="track.id" :track="track"></track-component>
    </div>
</template>

<script>
import { Track } from '../classes/track';
import TrackComponent from '../components/Track';

const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();

export default {
    components: {
        TrackComponent,
    },
    computed: {
        audioPlayer() {
            return this.$store.state.audioPlayer;
        },
        audioRecorder() {
            return this.$store.state.audioRecorder;
        },
        tracks() {
            return this.$store.state.tracks;
        },
    },
    created() {
        this.audioRecorder.addEventListener('dataavailable', this.onDataavailable);
        this.audioRecorder.addEventListener('start', this.onStart);
        this.audioRecorder.addEventListener('stop', this.onStop);
        window.addEventListener('keydown', this.keydownListener);
    },
    destroyed() {
        window.removeEventListener('keydown', this.keydownListener);
    },
    methods: {
        onDataavailable(event) {
            this.$store.commit('addTrack', new Track('Aufnahme ' + this.$store.state.nextTrackNumber, event.detail, audioContext));
        },
        onStart() {
            this.audioPlayer.rewind();
            this.audioPlayer.play();
        },
        onStop() {
            if(!this.audioPlayer.loop) {
                this.audioPlayer.stop();
            }
        },
        keydownListener(event) {
            if(event.code === 'KeyR') {
                this.audioRecorder.toggle();
                return;
            }
        },
    },
};
</script>
