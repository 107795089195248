<template>
    <div class="audio-player">
        <audio-track-row>
            <template v-slot:aside>
                <div class="audio-player-aside">
                    <div class="audio-player-aside-content">

                        <control-group>
                            <div class="btn-group">
                                <button @click="togglePlay" class="btn btn-outline-primary" title="Toggle Play (Space, Comma)">
                                    <b-icon icon="pause" :font-scale="1.2" v-if="audioPlayer.isPlaying"></b-icon>
                                    <b-icon icon="play" :font-scale="1.2" v-else></b-icon>
                                </button>
                                <button @click="stopOrReset" class="btn btn-outline-primary" title="Stop/Reset (0)">
                                    <b-icon icon="stop" :font-scale="1.2"></b-icon>
                                </button>
                                <button @click="rewind" class="btn btn-outline-primary" title="Rewind (Enter)">
                                    <b-icon icon="chevron-bar-left" :font-scale="1.2"></b-icon>
                                </button>
                                <button v-if="$store.state.config.audioRecorderEnabled" @click="toggleRecording" class="btn" :class="{'btn-outline-primary': !audioRecorder.isRecording, 'btn-danger': audioRecorder.isRecording}" title="Toggle Recording (R)">
                                    <b-icon icon="mic-fill" :font-scale="1.2" v-if="audioRecorder.isRecording"></b-icon>
                                    <b-icon icon="mic" :font-scale="1.2" v-else></b-icon>
                                </button>
                                <button v-if="$store.state.config.loopEnabled" @click="toggleLoop" class="btn" :class="[audioPlayer.loop ? 'btn-primary' : 'btn-outline-primary']" title="Loop (1)">
                                    <b-icon icon="arrow-repeat" :font-scale="1.2"></b-icon>
                                </button>
                            </div>
                        </control-group>

                        <control-group>
                            <div class="text-monospace">
                                {{ audioPlayer.currentTime | secondsToTime }}
                            </div>
                        </control-group>

                        <control-group v-if="$store.state.config.waveSurferRegionsPluginEnabled">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="region" v-model="showRegions">
                                <label class="custom-control-label" for="region">Show region</label>
                            </div>
                        </control-group>

                        <control-group v-if="$store.state.config.playbackRateEnabled" label="Playback rate" @labelClicked="resetPlaybackRate">
                            <slider v-model="audioPlayer.playbackRate" :min=".5" :max="2" :interval=".1"></slider>
                        </control-group>

                        <control-group label="Volume" :key="dirtyMutedFix">
                            <div class="d-flex w-100 align-items-center">
                                <button class="btn btn-sm mr-2" @click="toggleMuteMasterTrack" :class="{'btn-outline-primary': !audioPlayer.isMuted, 'btn-primary': audioPlayer.isMuted}">
                                    <b-icon :icon="audioPlayer.isMuted ? 'volume-mute-fill' : 'volume-mute'"></b-icon>
                                </button>
                                <div class="w-100">
                                    <slider v-model="audioPlayer.volume" :min="0" :max="1" :interval=".01"></slider>
                                </div>
                            </div>
                        </control-group>

                        <control-group>
                            <button v-if="$store.state.config.showStatistics" class="btn btn-sm btn-block btn-outline-primary" @click="downloadStatistics">
                                <b-icon icon="download"></b-icon>
                                Download statistics
                            </button>
                            <button v-if="$store.state.config.uploadAudioPlayerSrcEnabled" class="btn btn-sm btn-block btn-outline-primary" @click="removeAudioSrc">
                                <b-icon icon="trash"></b-icon>
                                Load new audio file
                            </button>
                        </control-group>

                    </div>
                    <div class="audio-player-aside-footer">
                        <stats-grid v-if="$store.state.config.showStatistics">
                            <stats-grid-col :data="audioPlayer.countPlays" label="Count plays"></stats-grid-col>
                            <stats-grid-col :data="audioPlayerCountSeconds" label="Count seconds"></stats-grid-col>
                            <template v-if="$store.state.config.audioRecorderEnabled">
                                <stats-grid-col :data="audioRecorder.countPlays" label="Recording tries"></stats-grid-col>
                                <stats-grid-col :data="audioRecorderCountSeconds" label="Recording seconds"></stats-grid-col>
                            </template>
                        </stats-grid>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <sound-wave ref="soundWave" v-if="audioSrcHelper" :src="audioSrcHelper" :current-time="currentTimeHelper" @regionUpdated="onRegionUpdated" @seek="onSeek" :show-regions="showRegions" :regions="regions"></sound-wave>
                <div v-else-if="$store.state.config.uploadAudioPlayerSrcEnabled" class="form-group p-3">
                    <label for="file">Upload audio file</label>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="file" @change="onChangeFileInput">
                        <label class="custom-file-label" for="file">Choose file</label>
                    </div>
                </div>
            </template>
        </audio-track-row>
    </div>
</template>

<script>
import { formatSeconds, secondsToTime } from '../functions/format-time';
import AudioTrackRow from '../templates/audio-track-row';
import SoundWave from '../components/SoundWave';
import StatsGrid from './StatsGrid';
import StatsGridCol from './StatsGridCol';
import ControlGroup from './ControlGroup';
import Slider from './Slider';

export default {
    components: {
        AudioTrackRow,
        SoundWave,
        StatsGrid,
        StatsGridCol,
        ControlGroup,
        Slider,
    },
    filters: {
        secondsToTime(seconds) {
            return secondsToTime(seconds);
        },
    },
    data() {
        return {
            currentTimeHelper: 0,
            audioSrcHelper: null,
            showRegions: false,
            dirtyMutedFix: 0,
        };
    },
    watch: {
        audioSrcHelper(value) {
            this.audioPlayer.src = value;
        },
    },
    computed: {
        audioPlayer() {
            return this.$store.state.audioPlayer;
        },
        audioRecorder() {
            return this.$store.state.audioRecorder;
        },
        audioPlayerCountSeconds() {
            return formatSeconds(this.audioPlayer.countSeconds);
        },
        audioRecorderCountSeconds() {
            return formatSeconds(this.audioRecorder.countSeconds);
        },
        regions() {
            return [
                {
                    start: this.audioPlayer.startTime,
                    end: this.audioPlayer.endTime,
                },
            ];
        },
    },
    created() {
        if(this.$store.state.config.src) {
            this.audioSrcHelper = this.$store.state.config.src;
        }
        this.audioPlayer.addEventListener('loadedmetadata', this.onLoadedmetadata);
        this.audioPlayer.addEventListener('ended', this.onEnded);
        this.audioPlayer.addEventListener('pause', this.onPause);
        this.audioPlayer.addEventListener('playing', this.onPlaying);
        this.audioPlayer.addEventListener('loopend', this.onLoopend);
        window.addEventListener('keydown', this.keydownListener);
    },
    destroyed() {
        clearInterval(this.seeker);
        window.removeEventListener('keydown', this.keydownListener);
    },
    methods: {
        toggleMuteMasterTrack() {
            this.dirtyMutedFix += 1;
            this.audioPlayer.toggleMute();
        },
        togglePlay(event) {
            this.audioPlayer.togglePlay();
            if(event) {
                event.currentTarget.blur();
            }
        },
        play(startAt) {
            this.audioPlayer.play(startAt);
        },
        pause() {
            this.audioPlayer.pause();
        },
        stopOrReset(event) {
            this.audioPlayer.stopOrReset();
            if(event) {
                event.currentTarget.blur();
            }
            this.forceUpdateSoundWave();
        },
        rewind(event) {
            this.audioPlayer.rewind();
            if(event) {
                event.currentTarget.blur();
            }
        },
        toggleLoop(event) {
            this.audioPlayer.toggleLoop();
            if(event) {
                event.currentTarget.blur();
            }
        },
        removeAudioSrc() {
            this.audioSrcHelper = null;
        },
        onEnded() {
            if(!this.audioPlayer.loop) {
                this.audioRecorder.stop();
            }
        },
        onPause() {
            if(!this.audioPlayer.loop) {
                this.audioRecorder.stop();
            }
        },
        onPlaying() {
            this.$store.dispatch('stopAllTracks');
        },
        async toggleRecording(event) {
            if(event) {
                event.currentTarget.blur();
            }
            try {
                await this.audioRecorder.toggle();
            } catch(e) {
                alert(e);
            }
        },
        onLoadedmetadata() {
            this.seeker = setInterval(() => {
                this.setCurrentTimeHelper();
                if(this.audioPlayer.currentTime > this.audioPlayer.endTime && this.audioPlayer.loop) {
                    this.audioPlayer.dispatchCustomEvent('loopend');
                    this.rewind();
                }
            }, 5);
        },
        setCurrentTimeHelper() {
            this.currentTimeHelper = this.audioPlayer.currentTime;
        },
        keydownListener(event) {
            if(event.code === 'Space' || event.code === 'Comma' || event.code === 'NumpadDecimal') {
                event.preventDefault();
                this.togglePlay();
                return;
            }
            if(event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                this.rewind();
                this.play();
                return;
            }
            if(event.code === 'Numpad0' || event.code === 'Digit0') {
                event.preventDefault();
                this.stopOrReset();
                return;
            }
            if(event.code === 'Numpad1' || event.code === 'Digit1') {
                event.preventDefault();
                this.toggleLoop();
                return;
            }
            if(event.code === 'Numpad2' || event.code === 'Digit2' || event.code === 'KeyK') {
                this.audioPlayer.startTime = this.audioPlayer.currentTime;
                this.showRegions = true;
                this.forceUpdateSoundWave();
                return;
            }
            if(event.code === 'Numpad3' || event.code === 'Digit3' || event.code === 'KeyL') {
                this.audioPlayer.endTime = this.audioPlayer.currentTime;
                this.showRegions = true;
                this.forceUpdateSoundWave();
                return;
            }
        },
        resetPlaybackRate() {
            this.audioPlayer.playbackRate = 1;
        },
        onRegionUpdated(region) {
            this.audioPlayer.startTime = region.start;
            this.audioPlayer.endTime = region.end;
        },
        downloadStatistics() {
            this.$store.dispatch('downloadStatistics');
        },
        onChangeFileInput(event) {
            const file = event.target.files[0] || null;
            if(file && file.type.includes('audio')) {
                this.audioSrcHelper = window.URL.createObjectURL(file);
            } else {
                throw new Error('Selected file is not of type audio');
            }
        },
        onSeek(progress) {
            this.audioPlayer.currentTime = this.audioPlayer.duration * progress;
        },
        onLoopend() {
            if(this.audioRecorder.isRecording) {
                this.audioRecorder.stop();
                this.audioRecorder.start();
            }
        },
        forceUpdateSoundWave() {
            if(this.showRegions) {
                this.$refs.soundWave.wavesurfer.clearRegions();
                this.regions.forEach(region => {
                    this.$refs.soundWave.wavesurfer.addRegion(region);
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/import";

    .audio-player-aside {
        border-right: 1px solid $border-color;
    }

    .audio-player-aside-content {
        padding: .5rem;
    }

    .audio-player-aside-footer {
        border-top: 1px solid $border-color;
    }
</style>
