export const formatSeconds = (s) => {
    return Math.round(s * 100) / 100 + 's';
};

export const secondsToTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = Math.floor(seconds % 60);
    const t = Math.ceil((seconds % 60 - s) * 1000);
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}.${t.toString().padStart(3, '0')}`;
};
