<template>
    <div class="sound-wave"></div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import WaveSurferCursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import WaveSurferRegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';

export default {
    data() {
        return {
            wavesurfer: null,
        };
    },
    props: {
        src: {
            type: String,
        },
        blob: {
            type: Blob,
        },
        height: {
            type: Number,
            default: 100,
        },
        barWidth: {
            type: Number,
            default: 0,
        },
        waveColor: {
            default: '#3B82F6',
        },
        progressColor: {
            default: '#8B5CF6',
        },
        audioContext: {
            default: null,
        },
        currentTime: {
            type: Number,
            default: 0,
        },
        regions: {
            type: Array,
            default: () => [],
        },
        showRegions: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        currentTime(value) {
            this.wavesurfer.drawer.progress(value / this.wavesurfer.backend.getDuration());
        },
        showRegions(value) {
            if(value) {
                this.regions.forEach(region => {
                    this.wavesurfer.addRegion(region);
                });
            } else {
                this.wavesurfer.clearRegions();
            }
        },
    },
    mounted() {
        const plugins = [];

        if(this.$store.state.config.waveSurferRegionsPluginEnabled) {
            plugins.push(WaveSurferRegionsPlugin.create({
                maxRegions: 1,
                regions: this.showRegions ? this.regions : [],
            }));
        }

        if(this.$store.state.config.waveSurferCursorPluginEnabled) {
            plugins.push(WaveSurferCursorPlugin.create({
                followCursorY: true,
                showTime: true,
                customShowTimeStyle: {
                    color: 'black',
                    padding: '0 .25rem',
                    margin: 'auto .25rem',
                    backgroundColor: 'rgba(255,255,255,.75)',
                },
            }));
        }

        this.wavesurfer = WaveSurfer.create({
            audioContext: this.audioContext,
            container: this.$el,
            waveColor: this.waveColor,
            progressColor: this.progressColor,
            height: this.height,
            cursorColor: 'black',
            barWidth: this.barWidth,
            responsive: true,
            plugins,
        });
        if(this.src) {
            this.wavesurfer.load(this.src);
        }
        if(this.blob) {
            this.wavesurfer.loadBlob(this.blob);
        }
        this.wavesurfer.on('region-updated', this.onRegionUpdated);
        this.wavesurfer.on('seek', this.onSeek);
    },
    methods: {
        onRegionUpdated(region) {
            this.$emit('regionUpdated', region);
        },
        onSeek(progress) {
            this.$emit('seek', progress);
        },
    },
};
</script>

<style scoped lang="scss">
    .sound-wave {
        position: relative;

        /deep/ .wavesurfer-region .wavesurfer-handle {
            width: 2px !important;
            background-color: rgba(0, 0, 0, .75) !important;
            @media (hover: none) {
                width: 10px !important;
                max-width: none !important;
                background-color: rgba(0, 0, 0, .5) !important;
            }
        }

        /deep/ showtitle {
            opacity: 1 !important;
        }
    }
</style>
