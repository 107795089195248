<template>
    <div class="confirm-field">
        <div v-if="mode === 'edit'" class="confirm-field-edit">
            <div class="confirm-field-edit-controls">
                <div class="confirm-field-edit-control-item">
                    <button class="btn btn-sm btn-light" @click="cancel">
                        <b-icon icon="x"></b-icon>
                    </button>
                </div>
                <div class="confirm-field-edit-control-item">
                    <button class="btn btn-sm btn-primary" @click="save">
                        <b-icon icon="check"></b-icon>
                    </button>
                </div>
            </div>
            <input v-model="currentValue" class="form-control">
        </div>
        <div v-else class="confirm-field-show" @click="edit">
            <span>{{ value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            mode: 'show',
            currentValue: null,
        };
    },
    watch: {
        mode(value) {
            if(value === 'edit') {
                window.addEventListener('keydown', this.keydownListener);
            } else {
                window.removeEventListener('keydown', this.keydownListener);
            }
        },
    },
    computed: {
        fieldValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        edit() {
            this.currentValue = this.value;
            this.mode = 'edit';
        },
        cancel() {
            this.mode = 'show';
        },
        save() {
            this.$emit('input', this.currentValue);
            this.mode = 'show';
        },
        keydownListener(event) {
            if(event.code === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                this.save();
                return;
            }
            if(event.code === 'Escape') {
                event.preventDefault();
                event.stopPropagation();
                this.cancel();
                return;
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .confirm-field-show span {
        cursor: pointer;
    }

    .confirm-field-edit {
        position: relative;

        .form-control {
            padding-right: 55px;
        }
    }

    .confirm-field-edit-controls {
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
    }

    .confirm-field-edit-control-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        .btn {
            padding: 0 .2rem;
            font-size: .875rem;
            margin-right: .3rem;
        }
    }
</style>
