<template>
    <div v-if="appInstanceHidden !== true" class="app" :style="appStyles" :class="`app-${$store.state.config.mode}`" :key="componentKey">
        <div :class="{container: $store.state.config.mode === 'page'}">
            <div class="app-logo">
                <div class="d-flex">
                    <div class="mr-auto">
                        <a href="https://www.mh-freiburg.de/">
                            <img src="./assets/mhfr-logo.svg" alt="Hochschule für Musik Freiburg">
                        </a>
                    </div>
                    <div class="ml-auto">
                        <a href="https://www.mh-freiburg.de/forschung/freiburger-forschungs-und-lehrzentrum-musik/das-freiburger-forschungs-und-lehrzentrum-musik">
                            <img src="./assets/fzm-logo.jpg" alt="Freiburger Forschungs- und Lehrzentrum Musik">
                        </a>
                    </div>
                </div>
            </div>
            <div class="app-audio-player">
                <audio-player-component></audio-player-component>
            </div>
            <div class="app-audio-recorder" v-if="$store.state.config.audioRecorderEnabled">
                <audio-recorder-component></audio-recorder-component>
            </div>
            <div class="app-license">
                License: <a href="https://creativecommons.org/licenses/by-nc/4.0/legalcode">CC BY-NC 4.0</a> |
                <a href="https://glarean.mh-freiburg.de/git/digiplay/audio-recorder">Version {{ version }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import AudioPlayerComponent from './components/AudioPlayer';
import AudioRecorderComponent from './components/AudioRecorder';

export default {
    components: {
        AudioPlayerComponent,
        AudioRecorderComponent,
    },
    data() {
        return {
            appInstanceHidden: false,
            componentKey: 0,
        };
    },
    computed: {
        appStyles() {
            if(this.$store.state.config.mode === 'absolute') {
                return this.$store.state.config.absoluteModeStyles;
            }
            return {};
        },
        version() {
            return process.env.VERSION;
        },
    },
    created() {
        window.audioRecorderAppInstance = this;
        this.init();
    },
    methods: {
        hide() {
            this.appInstanceHidden = true;
            this.$store.state.audioPlayer.stop();
        },
        show() {
            this.appInstanceHidden = false;
        },
        init(config) {
            this.resetConfig(config);
        },
        resetConfig(config) {
            this.$store.dispatch('resetConfig', Object.assign({}, window.config || {}, config || {}));
            this.forceRerender();
        },
        forceRerender() {
            this.componentKey += 1;
        },
    },
};
</script>

<style scoped lang="scss">
    .app-logo {
        margin-bottom: 1rem;

        img {
            height: 50px;
        }
    }
    .app-page {
        margin: 3rem 0;
    }
    .app-absolute {
        position: absolute;
    }
    .app-audio-recorder {
        margin-top: 1rem;
    }
    .app-audio-recorder /deep/ .track:not(:first-child) .audio-track-row {
        border-top-width: 0;
    }
    .app-license {
        margin-top: 1rem;
        font-size: .8rem;
        color: grey;
    }
</style>
