<template>
    <div>
        <vue-slider v-model="sliderValue" v-bind="params"></vue-slider>
    </div>
</template>

<script>
export default {
    data() {
        return {
            defaultConfig: {
                railStyle: {
                    borderRadius: 0,
                },
                processStyle: {
                    borderRadius: 0,
                },
            },
        };
    },
    props: ['value', 'min', 'max', 'interval'],
    computed: {
        params() {
            return Object.assign({}, this.defaultConfig, this.$props);
        },
        sliderValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
