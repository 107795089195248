<template>
    <div class="track">
        <audio-track-row>
            <template v-slot:aside>
                <div class="track-aside">
                    <div class="track-aside-content">

                        <button @click="remove" class="remove-track-button btn btn-sm btn-danger">
                            <b-icon icon="trash"></b-icon>
                        </button>

                        <control-group>
                            <confirm-field v-model="track.name"></confirm-field>
                        </control-group>

                        <control-group class="text-monospace">
                            {{ duration }}
                        </control-group>

                        <control-group v-if="$store.state.config.waveSurferRegionsPluginEnabled">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" :id="`region_${track.id}`" v-model="track.showRegion">
                                <label class="custom-control-label" :for="`region_${track.id}`">Show region</label>
                            </div>
                        </control-group>

                        <control-group>
                            <div class="btn-group btn-group-sm">
                                <button @click="togglePlay" class="btn btn-outline-primary">
                                    <b-icon icon="stop" :font-scale="1.2" v-if="track.isPlaying"></b-icon>
                                    <b-icon icon="play" :font-scale="1.2" v-else></b-icon>
                                </button>
                                <button @click="download" class="btn btn-outline-primary">
                                    <b-icon icon="download" :font-scale="1.2"></b-icon>
                                </button>
                            </div>
                        </control-group>

                        <control-group label="Volume">
                            <slider v-model="track.volume" :min="0" :max="1" :interval=".01"></slider>
                        </control-group>

                    </div>
                    <div class="track-aside-footer">
                        <stats-grid v-if="$store.state.config.showStatistics">
                            <stats-grid-col :data="track.countPlays" label="Count plays"></stats-grid-col>
                            <stats-grid-col :data="countSeconds" label="Count seconds"></stats-grid-col>
                        </stats-grid>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div :style="{width: trackWidth}">
                    <sound-wave :blob="track.blob" :current-time="track.currentTime" @regionUpdated="onRegionUpdated" @seek="onSeek" :show-regions="track.showRegion" :regions="[track.region]"></sound-wave>
                </div>
            </template>
        </audio-track-row>
    </div>
</template>

<script>
import { formatSeconds } from '../functions/format-time';
import AudioTrackRow from '../templates/audio-track-row';
import SoundWave from './SoundWave';
import StatsGrid from './StatsGrid';
import StatsGridCol from './StatsGridCol';
import ControlGroup from './ControlGroup';
import Slider from './Slider';
import ConfirmField from './ConfirmField';

export default {
    components: {
        AudioTrackRow,
        SoundWave,
        StatsGrid,
        StatsGridCol,
        ControlGroup,
        Slider,
        ConfirmField,
    },
    props: {
        track: {
            type: Object,
            required: true,
        },
    },
    computed: {
        countSeconds() {
            return formatSeconds(this.track.countSeconds);
        },
        duration() {
            return formatSeconds(this.track.duration);
        },
        trackWidth() {
            return '100%';
            // return this.track.duration / this.$store.state.audioPlayer.duration * 100 + '%';
        },
    },
    methods: {
        togglePlay(event) {
            if(this.track.isPlaying) {
                this.stop();
            } else {
                this.play();
            }
            event.currentTarget.blur();
        },
        play() {
            this.$store.dispatch('stopAllTracks');
            this.track.play(this.track.showRegion ? this.track.region.start || 0 : 0);
        },
        stop() {
            this.track.stop();
        },
        download(event) {
            this.track.download();
            event.currentTarget.blur();
        },
        remove() {
            this.$store.commit('removeTrack', this.track);
        },
        onSeek(progress) {
            this.track.play(this.track.duration * progress);
        },
        onRegionUpdated(region) {
            this.track.region.start = region.start;
            this.track.region.end = region.end;
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/import";

    .track-aside {
        border-right: 1px solid $border-color;
    }

    .track-aside-content {
        position: relative;
        padding: .5rem;
    }

    .track-aside-footer {
        border-top: 1px solid $border-color;
    }

    .remove-track-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: transparent;
        border-color: transparent;
        color: $body-color;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        transition: $transition-base;

        &:hover {
            background-color: $danger;
            border-color: $danger;
        }
    }

    .track:hover {
        .remove-track-button {
            opacity: 1;
        }
    }
</style>
