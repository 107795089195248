import audioBufferToWav from 'audiobuffer-to-wav';
import { v4 as uuidv4 } from 'uuid';
import { downloadBlob } from '../functions/download-blob';

export class Track {

    id = null;
    url = null;
    audioContext = null;
    blob = null;
    audioBuffer = null;
    name = null;
    _volume = 1;
    promise = null;
    gainNode = null;
    sourceNode = null;
    countSeconds = 0;
    countPlays = 0;
    timestamp = null;
    isPlaying = false;
    seeker = null;
    currentTime = 0;
    startAt = 0;
    showRegion  = false;
    region = {
        start: 0,
        end: 0,
    };


    constructor(name, blob, audioContext) {
        this.id = uuidv4();
        this.name = name;
        this.blob = blob;
        this.audioContext = audioContext;
        this.gainNode = this.audioContext.createGain();
        this.gainNode.connect(this.audioContext.destination);
        this.promise = new Promise((resolve, reject) => {
            this.url = window.URL.createObjectURL(this.blob);
            return fetch(this.url).then(response => {
                return response.arrayBuffer();
            }).then(buffer => {
                return this.audioContext.decodeAudioData(buffer, (audioBuffer) => {
                    this.audioBuffer = audioBuffer;
                    this.region.start = 0;
                    this.region.end = this.audioBuffer.duration;
                    resolve(audioBuffer);
                }, () => {
                    reject();
                });
            });
        });
    }

    async getAudioBuffer() {
        return this.audioBuffer || await this.promise;
    }

    get duration() {
        return this.audioBuffer ? this.audioBuffer.duration : null;
    }

    set volume(val) {
        this._volume = val;
        if(this.gainNode.gain) {
            this.gainNode.gain.setValueAtTime(this._volume, this.audioContext.currentTime);
        }
    }

    get volume() {
        return this._volume;
    }

    async play(startAt = 0) {
        this.stop();

        this.sourceNode = this.audioContext.createBufferSource();
        this.sourceNode.connect(this.gainNode);
        this.sourceNode.buffer = await this.getAudioBuffer();

        // this.sourceNode.addEventListener('ended', this.onEnded.bind(this));

        this.sourceNode.start(0, startAt);
        this.startAt = startAt;
        this.onStart();

        this.gainNode.gain.cancelScheduledValues(this.audioContext.currentTime);
        this.gainNode.gain.setValueAtTime(this.volume, this.audioContext.currentTime);
    }

    stop() {
        if(this.sourceNode) {
            this.sourceNode.disconnect();
            this.sourceNode.stop();
            this.sourceNode = null;
            this.onEnded();
        }
    }

    onStart() {
        this.countPlays += 1;
        this.timestamp = new Date();
        this.isPlaying = true;

        const audioContextStartTime = this.audioContext.currentTime;
        this.seeker = setInterval(() => {
            this.currentTime = this.audioContext.currentTime - audioContextStartTime + this.startAt;
            if(this.showRegion && this.currentTime > (this.region.end || this.duration)) {
                this.stop();
            }
            if(this.currentTime > this.duration) {
                this.onEnded();
            }
        }, 5);
    }

    onEnded() {
        this.setCountSeconds();
        this.timestamp = null;
        this.isPlaying = false;
        clearInterval(this.seeker);
    }

    setCountSeconds() {
        if(this.timestamp) {
            this.countSeconds += (new Date().getTime() - this.timestamp.getTime()) / 1000;
        }
    }

    download() {
        const wav = audioBufferToWav(this.audioBuffer);
        downloadBlob(new Blob([wav]), this.name + '.wav');
    }

}
