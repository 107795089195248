<template>
    <div class="audio-track-row" :style="rowAsideStyle">
        <div class="audio-track-row-aside">
            <slot name="aside"></slot>
        </div>
        <div class="audio-track-row-content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        rowAsideStyle() {
            return {
                '--aside-width': this.$store.state.config.audioTrackRowAsideWidth,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/import";

    .audio-track-row {
        display: grid;
        grid-template-columns: var(--aside-width) 1fr;
        border: 1px solid $border-color;
    }
</style>
