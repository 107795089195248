import Vue from 'vue';
import App from './App.vue';
import VueSlider from 'vue-slider-component';
import { BootstrapVueIcons } from 'bootstrap-vue';
import 'vue-slider-component/theme/default.css';
import './assets/scss/base.scss';
import store from './store';

Vue.config.productionTip = false;

Vue.component('VueSlider', VueSlider);
Vue.use(BootstrapVueIcons);

new Vue({
    store,
    render: h => h(App),
}).$mount((window.config ? window.config.elem : null) || '#audio-recorder-component');
